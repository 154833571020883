<template>
  <v-container>
    <v-card flat class="ma-0 transparent">
      <v-row class="ma-0">
        <v-col cols="12" class="ma-0">
          <v-card class="rounded my-2" style="position: relative;">
            <v-row class="ma-0">
              <v-col cols="12" sm="2" class="ma-0 ">
                <v-img width="180" :src="surveyPicture" />
              </v-col>

              <v-col cols="12" sm="6" class="ma-0">
                <v-card-title
                  >Voice Broadcast Campaign<v-badge
                    color="primary"
                    style="padding-left:5px"
                    content="BETA"
                  ></v-badge>
                </v-card-title>
                <v-card-text
                  >Welcome to GoDial's Voice Broadcast campaign, this feature
                  can also be called Press 1 Survey or Voice Mail Drop. You can
                  now send a voice broadcast to a list and you can capture and
                  set a dispositon if 1. You need a Twilio account, number and
                  credits for this to work. We also charge you 1 credit per call
                  we make, you can recharge credits by clicking the add credits
                  option.
                </v-card-text>
              </v-col>

              <v-col cols="12" sm="4" class="ma-0 text-center">
                <v-card-title class="justify-center">Credits</v-card-title>
                <v-card-text style="font-size: 30px;">
                  {{ companyDetails.surveyCredit }}
                </v-card-text>

                <v-btn depressed class="primarygrad" @click="addCredit"
                  >Add Credits</v-btn
                >
              </v-col>
            </v-row>
            <!-- <v-card-text class="ma-1 pa-2">
              <h2>
               
                <span class="px-1"> </span>
              </h2>
              <ul class="grey--text my-1">
                <li>Cycle:</li>
                <li>
                  Expiry:
                </li>
                <li>Member Limit :</li>
              </ul>
            </v-card-text> -->
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Twilio Settings</v-card-title>
          <v-card-subtitle
            >GoDial uses Twilio's Voip API to send voice calls and record
            responses. You will need a twilio account, a mobile number from
            twilio, calling balance. Open your twilio account
            <a href="https://twilio.com" target="_blank">here</a> and login to
            your
            <a href="https://console.twilio.com/" target="_blank">console</a> to
            see the credentials .
          </v-card-subtitle>
          <v-card-text>
            Account Sid<v-text-field
              v-model="twilioSetting.twilioAccountSid"
            ></v-text-field>
            Auth Token<v-text-field
              v-model="twilioSetting.twilioToken"
            ></v-text-field>
            My Twilio Phone Number<v-text-field
              v-model="twilioSetting.ownNumber"
            ></v-text-field>

            <div align="center">
              <v-btn depressed class="primarygrad" @click="saveTwillio"
                >Save</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>Call Settings</v-card-title>
          <v-card-text>
            Call on the following list
            <v-autocomplete
              v-model="selectedList"
              class="pt-1"
              :items="lists"
              item-text="name"
              item-value="id"
              label="Lists"
              autocomplete="false"
              prepend-inner-icon="view_list"
              hide-details
              dense
              background-color="inverted"
              outlined
              flat
              @change="foundTwillioAudioOrNot"
            />

            Broadcast Content
            <v-radio-group v-model="settingsType" row>
              <v-radio class="text-body-2" label="Text" value="tts" />
              <v-radio class="text-body-2" label="Audio" value="url" />
            </v-radio-group>

            <span v-if="settingsType == 'tts'">
              Play the following message
              <v-textarea v-model="audioText" rows="3"></v-textarea>
            </span>

            <span v-else>
              URL of the Audio File

              <input
                type="file"
                ref="file"
                @change="onFileSelect($event)"
                accept="audio/*"
              />
              <v-text-field v-model="audioURL" disabled></v-text-field>
            </span>

            <v-switch v-model="collectInput" label="Capture Input"></v-switch>

            <v-text-field
              v-if="collectInput"
              v-model="maxWaitTime"
              type="number"
              label=" Max Time to Wait for Response in Seconds"
            ></v-text-field>

            <span v-if="collectInput">
              If 1 is pressed set disposition to
              <v-autocomplete
                v-model="press1Dispo"
                class="pt-1"
                :items="dispoList"
                item-text="title"
                item-value="title"
                label="Disposition"
                autocomplete="false"
                prepend-inner-icon="view_list"
                hide-details
                dense
                background-color="inverted"
                outlined
                flat
              />

              If no answer / no response set disposition to
              <v-autocomplete
                v-model="negetiveDispo"
                class="pt-1"
                :items="dispoList"
                item-text="title"
                item-value="title"
                label="Disposition"
                autocomplete="false"
                prepend-inner-icon="view_list"
                hide-details
                dense
                background-color="inverted"
                outlined
                flat
              />

              <v-switch
                v-model="detectVoiceMail"
                label="Detect Voice Mail(Coming Soon)"
                disabled
              ></v-switch>

              <span v-if="detectVoiceMail">
                If voicemail is detected

                <v-radio-group v-model="voiceMailType" row>
                  <v-radio class="text-body-2" label="Text" value="Text" />
                  <v-radio class="text-body-2" label="Audio" value="Audio" />
                </v-radio-group>

                <span v-if="voiceMailType == 'Text'">
                  Play the following message
                  <v-textarea v-model="voiceMailText" rows="3"></v-textarea>
                </span>

                <span v-else>
                  URL of the Audio File
                  <v-text-field v-model="voiceMailUrl"></v-text-field>
                </span>
              </span>
            </span>
            <span v-else>
              Choose A Disposition To Set After Call
              <v-autocomplete
                v-model="normalDispo"
                class="pt-1"
                :items="dispoList"
                item-text="title"
                item-value="title"
                label="Disposition"
                autocomplete="false"
                prepend-inner-icon="view_list"
                hide-details
                dense
                background-color="inverted"
                outlined
                flat
              />
            </span>
          </v-card-text>

          <div
            align="center"
            style="padding : 10px;"
            v-if="companyDetails.surveyCredit > 2"
          >
            <v-btn
              v-if="state == 'PAUSED'"
              depressed
              class="primarygrad"
              @click="startSurvey"
              >START</v-btn
            >
            <v-btn
              v-if="state != 'PAUSED'"
              depressed
              dark
              class="primarygrad"
              @click="state = 'PAUSED'"
              >STOP</v-btn
            >
          </div>
          <div v-else>
            <small
              >You do not have sufficient credits in your account to start the
              campaign</small
            >
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="surveyLogs"
          :items-per-page="10"
          class="elevation-1"
        ></v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="addCreditFormPopup" persistent max-width="650">
      <v-card>
        <v-card-title class="primarygrad py-4 white " style="color:white">
          Add Call Credit
          <v-spacer></v-spacer>
          <v-btn icon @click="addCreditFormPopup = false">
            <v-icon color="white">mdi-close-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-5 mb-0 pb-0 px-10">
          <v-row style="padding: 0px; margin: 0px">
            <!-- <v-flex xs12 sm12 md12>
              <v-text-field
                v-model="contactForm.name"
                label="Name"
                required
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm12 md12>
              <v-text-field
                v-model="contactForm.email"
                label="Email"
                required
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm12 md12 style="padding-bottom:2%;">
              <vue-tel-input
                v-model="contactForm.phone"
                mode="international"
                :valid-characters-only="true"
                placeholder="Phone"
                class="secondary--text"
                @input="getSecondPhone"
              ></vue-tel-input>
            </v-flex> -->

            <v-flex xs12 sm12 md12>
              <v-select
                v-model="contactForm.limit"
                outlined
                dense
                label="Call Credit Limit"
                prepend-inner-icon="mdi-phone"
                :items="[
                  { text: '1 ($1)', value: 1 },
                  { text: '1000 ($7)', value: 10 },
                  {
                    text: '5000 ($35)',
                    value: 50,
                  },
                  { text: '10000 ($70)', value: 100 },
                  { text: '50000 ($350)', value: 500 },
                ]"
              />
            </v-flex>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="addCreditFormPopup = false"
            >Cancel</v-btn
          >
          <v-btn color="primary" @click="addCreditFormSave"> Purchase </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import disposer from "../js/disposer";
export default {
  data: () => {
    return {
      selectedFile: null,
      // twilioToken: "5a6ba3c25601dbb469af15793a5fc7ad",
      // twilioAccountSid: "AC20bd7e87579efadcad78ba52f880d582",
      // ownNumber: "+17044405616",
      twilioSetting: {
        twilioToken: "",
        twilioAccountSid: "",
        ownNumber: "",
      },
      contactForm: {
        name: "",
        phone: "",
        email: "",
        limit: 10,
      },
      addCreditFormPopup: false,
      normalDispo: "INTERESTED",
      collectInput: false,
      maxWaitTime: 60,
      state: "PAUSED",
      settingsType: "tts",
      selectedList: "",
      lists: [],
      loader: false,
      detectVoiceMail: false,
      voiceMailText: "",
      voiceMailUrl: "",
      voiceMailType: "Text",
      audioText:
        "Welcome to GoDial, To know more about the best Telecalling CRM Press 1",
      audioURL: "",
      press1Dispo: "INTERESTED",
      negetiveDispo: "NOT INTERESTED",
      headers: [
        { text: "Phone", value: "phone" },
        { text: "Name", value: "name" },
        { text: "Status", value: "dispo" },
      ],
      surveyLogs: [],
    };
  },
  computed: {
    companyDetails() {
      return this.$store.getters.companyDetails;
    },
    surveyPicture() {
      return require("../assets/survey.png");
    },
    ...mapGetters([
      "ENDPOINT",

      "teams",
      "fieldList",
      "dispoList",
      "selectedTeam",
    ]),
  },
  created() {
    var self = this;
    self.refresh();
  },
  methods: {
    async refresh() {
      var self = this;
      await this.fetchLists();
      await this.$store.dispatch("getCompanyDetails", {
        companyId: this.$store.state.companyDetails.id,
      });

      this.contactForm.name = this.$store.state.user.name;
      this.contactForm.email = this.$store.state.user.email;
      this.contactForm.phone = this.$store.state.user.phone;

      if (
        this.companyDetails &&
        typeof this.companyDetails.twilioSetting != "undefined"
      ) {
        this.twilioSetting = this.companyDetails.twilioSetting;
      } else {
        this.twilioSetting = {
          twilioToken: "",
          twilioAccountSid: "",
          ownNumber: "",
        };
      }

      await self.foundTwillioAudioOrNot();

      function loadScript(url, callback) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        if (script.readyState) {
          // only required for IE <9
          script.onreadystatechange = function() {
            if (
              script.readyState === "loaded" ||
              script.readyState === "complete"
            ) {
              script.onreadystatechange = null;
              callback();
            }
          };
        } else {
          //Others
          script.onload = function() {
            callback();
          };
        }

        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
      }

      loadScript("https://cdn.paddle.com/paddle/paddle.js", function() {
        if (self.$store.getters.isPaddleStaging == "true") {
          Paddle.Environment.set("sandbox");
        }

        //alert("Paddle key ", self.$store.getters.paddleKey);
        Paddle.Setup({ vendor: Number(self.$store.getters.paddleKey) });
      });
    },
    async foundTwillioAudioOrNot() {
      var res = await this.$http.get(
        `${this.$store.state.ENDPOINT}/lists/${this.selectedList}`
      );

      // console.log("res.body.twillioAudioUrl", res.body.twillioAudioUrl);

      if (res.body.twillioAudioUrl != "") {
        this.settingsType = "url";
        this.audioURL = res.body.twillioAudioUrl;
      } else {
        this.settingsType = "tts";
        this.audioText =
          "Welcome to GoDial, To know more about the best Telecalling CRM Press 1";
        this.audioURL = "";
      }
    },
    async onFileSelect(event) {
      var self = this;

      const file = event.target.files[0];

      const reader = new FileReader();

      console.log("reader", reader);

      reader.readAsDataURL(file);

      try {
        var formData = new FormData();
        formData.append("file", file);
        formData.append("type", "audio");
        formData.append("companyId", this.$store.state.companyDetails.id);
        var config = {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: this.$store.state.user.token,
          },
        };

        if (this.audioURL != "") {
          try {
            var resp = await this.$http.get(
              `${this.$store.state.ENDPOINT}/attachments?filter[where][name]=${this.audioURL}&filter[where][type]=audio`
            );
          } catch (e) {
            console.log(e);
          }

          try {
            await this.$http.delete(
              `${this.$store.state.ENDPOINT}/attachments/${resp.body[0].id}`
            );
          } catch (e) {
            console.log(e);
          }
        }

        //store the file to storage
        var attachment = await this.$http.post(
          `${this.$store.state.ENDPOINT}/attachments/upload`,
          formData,
          config
        );
        attachment = attachment.body;

        this.audioURL = attachment.name;

        try {
          var listObj = {
            twillioAudioUrl: this.audioURL,
          };

          await self.$http.patch(
            `${this.$store.state.ENDPOINT}/lists/${self.selectedList}`,
            listObj
          );
        } catch (e) {
          console.log("e", e);
        }

        console.log(
          `${this.$store.state.ENDPOINT}/storages/get/${this.audioURL}?access_token=${this.$store.getters.user.token}`
        );

        setTimeout(() => {
          this.refresh();
          this.$swal({
            type: "success",
            text: "Audio file uploaded successfully",
          });
        }, 5000);
      } catch (e) {
        console.log(e);
        // this.loader = false;
      }
    },

    async addCreditFormSave() {
      var self = this;

      var productId = self.$store.state.voiceBlastProduct;

      Paddle.Checkout.open({
        product: productId,
        email: self.$store.getters.user.email,
        quantity: Number(self.contactForm.limit),
        passthrough: self.$store.getters.user.companyId,
        successCallback: async function(data) {
          console.log("data", data);

          // var checkoutId = data.checkout.id;

          var response = await self.$http.get(
            `${self.$store.state.ENDPOINT}/companies/${self.$store.getters.user.companyId}`
          );

          self.$store.dispatch("getCompanyDetails", {
            companyId: self.$store.state.companyDetails.id,
          });

          self.refresh();
          this.addCreditFormPopup = false;
          try {
            self.$swal({
              type: "success",
              text:
                "Thank you for subscribing to GoDial. Your account will updated shortly, Kindly refresh after 1 minute.",
            });
          } catch (e) {
            console.log(e);
          }

          // Paddle.Order.details(checkoutId, function(data2) {
          //   // Order data, downloads, receipts etc... available within 'data' variable.
          //
          // });
        },
        closeCallback: function(data) {
          this.addCreditFormPopup = false;
          console.log("data", data);
          try {
            self.$swal({
              type: "error",
              text: "Some error occured",
            });
          } catch (e) {
            console.log(e);
          }
        },
      });
    },
    getSecondPhone(event, phone) {
      if (event) {
        return (this.contactForm.phone = phone.number.e164);
      } else {
        return (this.contactForm.phone = "");
      }
    },
    async saveTwillio() {
      const self = this;

      // //First verify the details

      // var res = await self.$http.post(
      //   `${this.ENDPOINT}/cloudTelephonies/testtwilio`,
      //   {
      //     accountSid: this.twilioSetting.twilioAccountSid,
      //     token: this.twilioSetting.twilioToken
      //   }
      // );

      // console.log(res);

      // return;

      self.$http
        .patch(
          `${this.ENDPOINT}/companies/${self.$store.getters.user.companyId}`,
          { twilioSetting: this.twilioSetting }
        )
        .then((response) => {
          self.$store.dispatch("getCompanyDetails", {
            companyId: self.$store.getters.user.companyId,
          });
          console.log("response", response.body);
          self.$swal({ type: "success", text: "Updated" });
        })
        .catch((err) => {
          self.$swal({
            type: "error",
            text: "Something went wrong. Couldn't Update",
          });
        });
    },
    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }

      teams = _.without(teams, "allTeams");

      return teams;
    },
    // Fetch lists
    async fetchLists() {
      try {
        this.lists = [];
        var response = [];

        var teamIds = this.getTeamIds();

        // Teams sections
        response = await this.$http.post(`${this.ENDPOINT}/lists/fetch`, {
          teams: teamIds,
        });
        this.lists = response.body;

        if (this.lists.length < 1) {
          this.loader = false;
          return;
        }

        this.selectedList = this.lists.length > 0 ? this.lists[0].id : "";
      } catch (e) {
        this.loader = false;
        console.log("error in list fetch", e);
      }
    },
    nextCall() {
      const self = this;
      return new Promise(function(resolve, reject) {
        var callItem = false;
        self.$http
          .get(
            `${self.$store.state.ENDPOINT}/contacts/next?listId=${self.selectedList}&dialerAction=place_call&v2=3`
          )
          .then((res) => {
            if (res.data.success) {
              callItem = res.data.contact;
              callItem.direction = "outgoing";
              if (
                typeof callItem.customFields === "undefined" ||
                Array.isArray(callItem.customFields)
              ) {
                if (typeof callItem.customFields === "undefined")
                  callItem.customFields = {};
                for (var index in self.fieldList) {
                  //callItem[fieldList[index].name] = ''; fieldList is about custom fields and not contact
                  callItem.customFields[self.fieldList[index].name] = "";
                }
              }
              resolve(callItem);
            } else {
              reject(res);
            }
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },
    addCredit() {
      this.addCreditFormPopup = true;
    },
    // async setDisposition(callItem) {
    //   const self = this;

    //   callItem.called = true;
    //   callItem.duration = 0;
    //   callItem.timeSpentOnCall = 0;
    //   await this.$http.post(`${self.$store.state.ENDPOINT}/contacts/dispose`, {
    //     contact: callItem,
    //     type: "call",
    //     returnNextCall: false,
    //     listId: this.selectedList,
    //     dialerAction: "place_call",
    //     v: 3
    //   });
    // },
    async startSurvey() {
      var self = this;

      console.log("start survey", self.companyDetails.surveyCredit);

      if (self.settingsType == "url") {
        if (self.audioURL == "") {
          return this.$swal({
            type: "error",
            text: "Please upload a Audio",
          });
        }
      }

      if (this.state == "PAUSED") this.state = "DIALING";

      if (
        this.selectedList == "" ||
        this.twilioSetting.twilioToken == "" ||
        this.twilioSetting.twilioAccountSid == "" ||
        this.twilioSetting.ownNumber == ""
      ) {
        alert("Please select ");
        this.state = "PAUSED";
        return;
      }

      while (this.state == "DIALING") {
        console.log("Starting call");
        try {
          var callItem = await this.nextCall();
        } catch (e) {
          this.state = "PAUSED";
          console.log(e);
          this.$swal({
            type: "error",
            text: e.body.message,
          });
          break;
        }

        console.log("Got next call", callItem);

        if (callItem.phone) {
          if (this.companyDetails.surveyCredit <= 0) {
            this.$swal({
              type: "error",
              text:
                "You don't have enough credits to make calls. Please add credits to continue",
            });
            this.state = "PAUSED";
            break;
          }

          try {
            await this.placeCall(callItem);
          } catch (e) {
            console.log(e);
            this.state = "PAUSED";

            console.log(e);
            this.$swal({
              type: "error",
              text: e.body.error.message,
            });
            break;
          }

          console.log("Enf of place call");
        }
      }
    },
    async placeCall(callItem) {
      //Get the next number from the list
      this.surveyLogs.push({
        phone: callItem.phone,
        name: callItem.name,
        dispo: "CALLED",
      });

      console.log("callItem", callItem);

      callItem.timeSpentOnCall = 0;
      callItem.callStart = new Date().getTime();
      callItem.calledOn = moment().format();
      var res = false;
      //Send it to twilio
      try {
        res = await this.$http.post(
          this.ENDPOINT + "/cloudTelephonies/callwithtwilio",
          {
            to: callItem.phone,
            from: this.twilioSetting.ownNumber,
            twilioToken: this.twilioSetting.twilioToken,
            twilioAccountSid: this.twilioSetting.twilioAccountSid,
            audioSrc:
              this.settingsType == "tts"
                ? this.audioText
                : `${this.$store.state.ENDPOINT}/storages/get/${this.audioURL}?access_token=${this.$store.getters.user.token}`,
            audioMode: this.settingsType,
            collectInput: this.collectInput,
            maxWaitTime: this.maxWaitTime.toString(),
          }
        );
      } catch (e) {
        console.log(e);
        console.log("Error placing this call, or did not get any response");
      }
      var dispo = this.negetiveDispo;

      if (res) {
        var response = res.body;

        if (response == "1") {
          dispo = this.press1Dispo;
        }

        if (!this.collectInput) dispo = this.normalDispo;
      }

      this.surveyLogs.push({
        phone: callItem.phone,
        name: callItem.name,
        dispo: dispo,
      });

      callItem.defaultDialer = "TwilioSurvey";
      callItem.accountsId = this.$store.state.user.id;
      callItem.dispo = dispo;

      //Dispose the call with the disposition selected if 1 is pressed
      await disposer.setDisposition(callItem, false);
    },
  },
};
</script>
